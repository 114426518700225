@import './../node_modules/@latch/latch-web/styles/variables';

$vertical-padding: 24px;
$horizontal-padding: 30px;

$latch-page-margin: 40px;
$latch-header-height: 44px;

html,
body {
  margin: 0;
}
.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-bottom: 15px;
}
.centered-button {
  width: 72%;
  display: block;
  text-align: center;
}
.element-padding {
  padding: 15px;
}
.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card img {
  max-width: 100%;
}

.footer {
  padding: $horizontal-padding;
  align-items: center;
  justify-content: center;

  .latch-button,
  .latch-button-outline {
    width: 72%;
    display: block;
    text-align: center;
  }
}
.latch-powered {
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.latch-powered-logo-light,
.latch-powered-logo-dark {
  height: 60px;
  min-width: 200px;
}

.header-graphic {
  max-width: 100%;
}
.latch-page {
  max-width: 500px;
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  margin: 0 auto;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 100vh;

  > * {
    flex: 1;
  }
}

.header-text {
  font-size: 24px;
  margin-bottom: 60px;
}

.title {
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: bold;
  line-height: 36px;
  color: $color-black;
}

@media only screen and (max-width: 768px) {
  body {
    margin: 0px;
  }

  .card {
    margin-bottom: 0px;
  }
}

.doorcode-display .latch-divider {
  display: none;
}

$qr-scanner-width: 320px;
#qr-scanner {
  width: $qr-scanner-width;
  height: 240px;
  border-radius: 10px;
  position: relative;
  margin: auto;
  overflow: hidden;
}
.error-message {
  width: $qr-scanner-width;
  text-align: center;
  color: $color-red;
  margin: 5px auto;
}

// Icons
@mixin icon($url, $width, $height) {
  width: $width;
  height: $height;
  background-image: url($url);
  background-size: $width $height;
  background-position: center center;
  background-repeat: no-repeat;
}
.icon-calendar {
  @include icon('assets/icons/calendar.svg', 18px, 18px);
}
.cell-admission-time-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.text-admission-date {
  margin-left: 10px;
  font-weight: bold;
}
.text-admission-time {
  margin-left: auto;
}
.cell-admission,
.access-information {
  padding-top: 15px;
  padding-bottom: 15px;
}
.icon-door {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.icon-door-background {
  width: 60px;
  height: 60px;
  background-color: $color-gray-3;
  border-radius: 10px;

  display: table;
}
.door-public {
  @include icon('assets/icons/door-public.svg', 40px, 40px);
}
.door-private {
  @include icon('assets/icons/door-private.svg', 40px, 40px);
}
.cell-door-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $color-gray-3;
  box-shadow: 0 0 10px $color-gray-3;
  border-radius: 10px;
  margin-top: 10px;
  min-height: 60px;
  padding: 10px;
}
.cell-door-code-inner-container {
  height: 48px;
  margin-left: 20px;
}
.text-door-code {
  font-weight: bold;
  margin-top: 8px;
}
.icon-door-code-time {
  margin-left: auto;

  width: 35px;
  height: 35px;
}

markdown {
  padding: $vertical-padding $horizontal-padding;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  p {
    margin-bottom: 0px;
  }
  p:last-of-type {
    margin-bottom: 0px;
  }
  img {
    margin: 25px auto;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    min-width: 100%;
    display: block;
    border-radius: 10px;
  }
}

.hide {
  display: none !important;
}

.admittance-time {
  padding: 0 $horizontal-padding;
}

.create-account-wrapper,
.temporary-doorcode-security-message,
.serial-number-entry-field {
  padding: $vertical-padding $horizontal-padding;
}

.doorcode-display {
  padding: 0 $horizontal-padding;
  display: flex;
  flex: 1;
  flex-direction: column;
}

// make this field flush with label above
.serial-number-entry-field {
  padding-top: 0px;
}

// Hide the dark logo by default
.latch-powered-logo-dark {
  display: none;
}

// Hide app related content
.app-access-information,
.app-access-button {
  display: none;
}
